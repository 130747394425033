import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
const initialState = {
    token: null,
    mulchYear: null,
    loaderId: null,
    delivery:null,
    orders:[],
    updated: false,
    errorMessage: null,
    loading: false,
    driver: null
};

const updateOrder = (state, action) => {
    return {
        ...state,
        order: {
            ...state.order,
            [action.idToken]: action.value
        }
    }
};

const loadStart = ( state, action ) => {
    return {
        ...state, 
        errorMessage: null,
        loading: true 
    } 
};

const loadResult = (state, action) => {
    return {
        ...state,
        loading: false,
        [action.idToken]: action.value
    }
}

const messageFail = (state,action) => {
    console.log(action);
        return {
            ...state, 
            loading: false ,
            errorMessage: action.error
        }    
    
}

const authSuccess = (state, action) => {
    return {
        ...state,  
        token: action.idToken,
        loaderId: action.loaderId,
        errorMessager: null,
        loading: false,
        deliveries: [],
     };
};

const signUpSuccess = (state, action) => {
    return {
        ...state,  
        token: action.idToken,
        loaderId: action.loaderId,
        errorMessage: null,
        loading: false
     };
};

const authLogout = (state, action) => {
    return {
        ...state, 
        token: null, 
        loaderId: null,
        deliveries: []
    };
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.LOAD_RESULT: return loadResult(state,action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.SIGNUP_SUCCESS: return signUpSuccess(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.MESSAGE_FAIL: return messageFail(state,action);
        default: return state;
    }
};

export default reducer;