import * as actionTypes from './actionTypes';
import axios from '../../axios-orders';


export const initOrder = () => {
    return {
        type: actionTypes.INIT_ORDER
    };
};

export const initSearchResults = () => {
    return {
        type: actionTypes.INIT_SEARCH_RESULTS
    };
};

export const changeCurrentDeliveryOrder = (oid) => {
    return (dispatch, getState) => {
        const { delivery } = getState().orders;
        let site = window.location.hostname;
        site = site.toLowerCase().replace('drv', '');
        if (site === '') {
            site = 'local';
        }
        axios.get('https://api.mescout.org/api/Delivery/ChangeCurrentDeliveryOrder?site=' + site + '&deliveryId=' + delivery.deliveryId + '&orderId=' + oid)
            .then(res => {
                dispatch(loadResult('delivery', res.data));
            })
            .catch(err => {
                dispatch(messageFail(err));
            });
    };
};



export const updateOrder = (idToken, value) => {
    return {
        type: actionTypes.UPDATE_ORDER,
        idToken: idToken,
        value: value
    };
};

export const getDriver = () => {
    return (dispatch) => {
        const driverId = localStorage.getItem('driverId');
        let site = window.location.hostname;
        site = site.toLowerCase().replace('drv', '');
        console.log(site);
        if (site === '') {
            site = 'local';
        }
        axios.get('https://api.mescout.org/api/DeliveryDriver/GetDriver?site=' + site + '&driverId=' + driverId)
            .then(res => {
                dispatch(loadResult('drv', res.data));
            })
            .catch(err => {
                dispatch(messageFail(err));
            });
    };
}


export const messageFail = (errorMessage) => {
    if (errorMessage === 'Security Exception') {
        return (dispatch) => {
            dispatch(logout());
        }

    }
    else {
        return {
            type: actionTypes.MESSAGE_FAIL,
            error: errorMessage,
            loading: false
        }
    }
};

export const loadStart = () => {
    return {
        type: actionTypes.LOAD_START
    };
};


export const updateDriverStatus = (status) => {
    return (dispatch) => {
        const driverId = localStorage.getItem('driverId');
        let site = window.location.hostname;
        site = site.toLowerCase().replace('drv', '');
        if (site === '') {
            site = 'local';
        }
        axios.get('https://api.mescout.org/api/DeliveryDriver/UpdateDriverStatus?site=' + site + '&driverId=' + driverId + '&status=' + status)
            .then(res => {
                dispatch(loadResult('drv', res.data));
            })
            .catch(err => {
                dispatch(messageFail(err));
            });
    };
}

export const updateDeliveryStatus =(deliveryId,status) => {
    return (dispatch) => {
        let site = window.location.hostname;
        site = site.toLowerCase().replace('drv', '');
        if (site === '') {
            site = 'local';
        }
        axios.get('https://api.mescout.org/api/Delivery/UpdateDeliveryStatus?site=' + site + '&status=' + status + '&deliveryId=' + deliveryId)
            .then(res => {
                dispatch(loadResult('delivery', res.data));
                dispatch(updateDriverStatus(status))
            })
            .catch(err => {
                dispatch(messageFail(err));
            });
    };
}

export const getMulchYear = () => {
    return (dispatch, getState) => {
        let site = window.location.hostname;
        site = site.toLowerCase().replace('drv', '');
        if (site === '') {
            site = 'local';
        }
        let url = 'https://api.mescout.org/api/MulchOrder/GetMulchYear?site=' + site ;

        axios.post(url)
            .then(response => {                
                dispatch(loadResult('mulchYear',  response.data));                
            })
            .catch(err => {
                dispatch(messageFail(err));
            });
    };
};

export const updateDeliveryOrder = (deliveryId,orderId) => {

}

export const loadResult = (idToken, value) => {

    return {
        type: actionTypes.LOAD_RESULT,
        idToken: idToken,
        value: value
    };
};



export const signUpSuccess = (token, driverId) => {
    return {
        type: actionTypes.SIGNUP_SUCCESS,
        idToken: token,
        driverId: driverId
    };
};


export const logout = () => {
    localStorage.removeItem('driverToken');
    localStorage.removeItem('driverId');
  
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};



export const auth = (phoneNumber, isSignup) => {
    return dispatch => {
        dispatch(loadStart());
        let site = window.location.hostname;
        site = site.toLowerCase().replace('drv', '');
        if (site === '') {
            site = 'local';
        }
        let url = 'https://api.mescout.org/api/Security/LoginDriver?site=' + site + '&phone=' + phoneNumber;

        axios.post(url)
            .then(response => {
                console.log(response.data);
                if (response.data.driverId) {
                    dispatch(loadResult('drv', response.data));
                    localStorage.setItem('driverToken', response.data.SessionId);
                    localStorage.setItem('driverId', response.data.driverId);
                    dispatch(authSuccess(response.data.SessionId, response.data.driverId));
                    dispatch(getDelivery());        
                }
                else {
                    dispatch(messageFail('invalid phone number'));
                }
            })
            .catch(err => {
                dispatch(messageFail(err.response.data.error));
            });
    };
};

export const authSuccess = (token, driverId) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        driverId: driverId
    };
};

export const signUp = (firstName, lastName,  phone, numberOfBags) => {
    return dispatch => {
        dispatch(loadStart());
        let site = window.location.hostname;
        site = site.toLowerCase().replace('drv', '');
        if (site === '') {
            site = 'localhost';
        }
        let url = 'https://api.mescout.org/api/Security/SignUpDriver?site=' + site + '&firstName=' + firstName +
            '&lastName=' + lastName + '&phone=' + phone +
            '&numberOfBags=' + numberOfBags;
        axios.post(url)
            .then(response => {
                
                if (response.data.LoaderId) {
                    localStorage.setItem('driverToken', response.data.SessionId);
                    localStorage.setItem('driverId', response.data.DriverId);
                    dispatch(signUpSuccess(response.data.SessionId, response.data.LoaderId));

                }
                else {
                    console.log(response.data);
                    dispatch(messageFail(response.data));
                }
            })
            .catch(err => {
                console.log(err);
                dispatch(messageFail(err));
            });
    };
};


export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('driverToken');
        if (!token) {
            dispatch(logout());
        } else {
            const driverId = localStorage.getItem('driverId');
            console.log("DRIVER LOGGED IN " + driverId);
            dispatch(authSuccess(token,driverId ));
            dispatch(getDelivery());
            dispatch(getDriver());
        }
    };
};

export const deliverOrder = () => {
    return (dispatch, getState) => {
        const { delivery } = getState().orders;
        let site = window.location.hostname;
        site = site.toLowerCase().replace('drv', '');
        if (site === '') {
            site = 'local';
        }

        axios.get('https://api.mescout.org/api/Delivery/DeliverOrder?site=' + site  + '&deliveryId=' + delivery.deliveryId + '&orderId=' + delivery.currentOrder)
            .then(res => {
                dispatch(loadResult('delivery', res.data));
            })
            .catch(err => {
                dispatch(messageFail(err));
            });

    };
}

export const removeOrder = (message) => {
    return (dispatch, getState) => {
        const { delivery } = getState().orders;
        let site = window.location.hostname;
        site = site.toLowerCase().replace('drv', '');
        if (site === '') {
            site = 'local';
        }

        axios.get('https://api.mescout.org/api/Delivery/RemoveOrderFromDelivery?site=' + site  + '&deliveryId=' + delivery.deliveryId + '&orderId=' + delivery.currentOrder + '&message=' + message)
            .then(res => {
                dispatch(loadResult('delivery', res.data));
            })
            .catch(err => {
                dispatch(messageFail(err));
            });

    };
}


export const getDelivery = () => {

    return (dispatch, getState) => {
        const { driver } = getState().orders;
        let driverId = localStorage.getItem('driverId');
        console.log(driverId);
        //dispatch(loadStart());
        const { orders } = getState().orders;
        let site = window.location.hostname;
        site = site.toLowerCase().replace('drv', '');
        if (site === '') {
            site = 'local';
        }
        axios.get('https://api.mescout.org/api/Delivery/GetAssignedDelivery?site=' + site +
            '&driverId=' + driverId)
            .then(res => {
                dispatch(loadResult('delivery', res.data));
            })
            .catch(err => {
                dispatch(messageFail(err));
            });
    };
};

