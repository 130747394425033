import React, { Component } from 'react';
import { connect } from 'react-redux';
import classes from './DeliveryMapOptions.module.css';
import Button from '../UI/Button/Button';
import ReactInterval from 'react-interval';
import * as actions from '../../store/actions/index';


class DeliveryMapOptions extends Component {
    state = {
        enabled: false
    }

    getDriver = () => {
        this.props.getDriver();
        this.props.getDelivery();
    }

    render() {
        let delivery = this.props.delivery;
        if (delivery == '') {
            delivery = null;
        }
        console.log(delivery);
        if (this.props.mulchYear) {
            
            let hardwood = null;
            let cedar = null;
            let black = null;
            let red = null;
            let pallets = 0;
            let hardwoodBags = 0;
            let blackBags = 0;
            let cedarBags = 0;
            let redBags = 0;
            let hardwoodPallet = parseInt(this.props.mulchYear.hardwoodPallet);
            let cedarPallet = parseInt(this.props.mulchYear.cedarPallet);
            let blackPallet = parseInt(this.props.mulchYear.blackPallet);
            let redPallet = parseInt(this.props.mulchYear.redPallet);

            if (delivery && delivery.orders && delivery.orders.length > 0) {
                for (let l in delivery.orders) {
                    if (delivery.orders[l].status !== 'delivered') {
                        hardwoodBags = hardwoodBags + parseInt(delivery.orders[l].hardwoodBags, 10);
                        blackBags = blackBags + parseInt(delivery.orders[l].blackBags, 10);
                        cedarBags = cedarBags + parseInt(delivery.orders[l].cedarBags, 10);
                        redBags = redBags + parseInt(delivery.orders[l].redBags, 10);
                    }
                }
                if (hardwoodBags > 0) {
                    pallets = Math.floor(hardwoodBags / hardwoodPallet);
                    hardwoodBags = hardwoodBags % hardwoodPallet
                    hardwood = <div>Hardwood {pallets}  pallets plus {hardwoodBags} bags.</div>
                }

                if (cedarBags > 0) {
                    pallets = Math.floor(cedarBags / cedarPallet);
                    cedarBags = cedarBags % cedarPallet;
                    cedar = <div>Cedar {pallets}  pallets plus {cedarBags}  bags.</div>
                }

                if (blackBags > 0) {
                    pallets = Math.floor(blackBags / blackPallet);
                    blackBags = blackBags % blackPallet;
                    black = <div>Black {pallets} pallets plus {blackBags} bags.</div>
                }

                if (redBags > 0) {
                    pallets = Math.floor(redBags / redPallet);
                    redBags = redBags % redPallet;
                    red = <div>Red {pallets} pallets plus {redBags} bags.</div>
                }
            }
console.log(this.props.driver)
            if (this.props.driver && this.props.driver.status == 'ready') {
                return (
                    <div className={classes.DeliveryMapOptions}>
                        <p>
                            Please stand by while we
                            <br />allocate you a delivery
                        </p>

                        <Button
                            btnType="Danger"
                            clicked={this.props.unavailable}>Unavailable</Button>

<ReactInterval timeout={10000} enabled={true}
          callback={() => this.getDriver()} />
                    </div>
                )
            }
            else if (this.props.driver && this.props.driver.status == 'unavailable') {
                return (
                    <div className={classes.DeliveryMapOptions}>
                        <p>
                            When you are ready to resume deliveries
                            <br />click Available for Next Delivery
                        </p>

                        <Button
                            btnType="Success"
                            clicked={this.props.available}>Available for Next Delivery</Button>
                    </div>
                )
            }
            else if (!delivery || delivery.status == 'delivered') {
                console.log(!delivery);
                return (
                    <div className={classes.DeliveryMapOptions}>
                        <p>
                            Please click Available for Next Delivery
                            <br />to set  yourself ready for another
                            <br />delivery, or Unavailable if you 
                            <br />need to take a break.
                        </p>
                        <Button
                            btnType="Success"
                            clicked={this.props.available}>Available for Next Delivery</Button>

                        <Button
                            btnType="Danger"
                            clicked={this.props.unavailable}>Unavailable</Button>
                    </div>
                )
            }
            else if (delivery  && delivery.status != 'delivering' && this.props.delivery.status != 'delivered') {
                return (
                    <div className={classes.DeliveryMapOptions}>
                        <Button
                            btnType="Success"
                            clicked={this.props.loaded}>Loaded</Button>
                        {hardwood}
                        {cedar}
                        {black}
                        {red}
                    </div>
                )
            } else {
                if (this.props.delivery == '') {
                    console.log("IT IS BLANK")
                }
                return (
                    <div className={classes.DeliveryMapOptions}>
                        {hardwood}
                        {cedar}
                        {black}
                        {red}
                    </div>
                )
            }
        }
        else {
            if (this.props.delivery == '') {
                console.log("IT IS BLANK")
            }
            return (
                <div />
            )
        }

    }
};

const mapDispatchToProps = dispatch => {
    return {
        getDriver: () => dispatch(actions.getDriver()),
        getDelivery: () => dispatch(actions.getDelivery())
    }
}

const mapStateToProps = state => {
    return {
        delivery: state.orders.delivery,
        mulchYear: state.orders.mulchYear,
        driver: state.orders.drv
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(DeliveryMapOptions);