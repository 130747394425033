import React, { Component } from 'react';
import classes from './SplitLabel.module.css';
class SplitLabel extends Component {
    render () {

        if (this.props.type && this.props.type == 'link') {
            return (
                <div>
                    <span className={classes.LeftSide}>
                        <strong>{this.props.left}</strong>
                    </span>
                    <span className={classes.RightSide}>
                        &nbsp;<a target='blank' href={this.props.href}>{this.props.right}</a>
                    </span>
                </div>
                )
        }
        else {

    return (
    <div className={classes.SplitLabel}>
        <span className={classes.LeftSide}>
            <strong>{this.props.left}</strong>
        </span>
        <span className={classes.RightSide}>
            &nbsp;{this.props.right}
        </span>
    </div>
    )
        }
    }
}

export default SplitLabel