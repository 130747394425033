import React, { Component } from 'react';
import { connect } from 'react-redux';
import classes from './DeliveryOrder.module.css';
import Button from '../UI/Button/Button';
import SplitLabel from '../SplitLabel/SplitLabel';
import SplitLabel3 from '../SpltLabel3/SplitLabel3';
import DeliveryOrderButtons from '../DeliveryOrderButtons/DeliveryOrderButtons';

class DeliveryOrder extends Component {
    render() {
        let lets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let letter = 0
        let actual = 0;
        if (this.props.delivery.orders && this.props.delivery.orders.length > 0) {
            let delivery = null;
            if (this.props.delivery.currentOrder !== 0) {
                for (let l in this.props.delivery.orders) {
                    letter = letter + 1;
                    if (this.props.delivery.orders[l].orderId == this.props.delivery.currentOrder && this.props.delivery.orders[l].status !== 'delivered') {
                        delivery = this.props.delivery.orders[l];
                        actual = letter 
                    }
                }
            }
            if (delivery) {
                let hardwood = null;
                let cedar = null;
                let black = null;
                let red = null;
                if (delivery.hardwoodBags > 0) {
                    hardwood = <SplitLabel left='Hardwood:' right={delivery.hardwoodBags} /> 
                }
                if (delivery.cedarBags > 0) {
                    cedar = <SplitLabel left='Cedar:' right={delivery.cedarBags} /> 
                }
                if (delivery.blackBags > 0) {
                    black = <SplitLabel left='Black:' right={delivery.blackBags} /> 
                }
                if (delivery.redBags > 0) {
                    red = <SplitLabel left='Red:' right={delivery.redBags} /> 
                }
                let h = 'https://www.google.com/maps/place/' + delivery.geoAddress;
                let t = 'tel://' + delivery.phone;
                return (
                    <div className={classes.DeliveryOrder}>
                        <SplitLabel left='Delivery:' right={actual} />
                        <SplitLabel3 left='Name:' middle={delivery.name} right={delivery.phone} rightType='phone' />
                        <SplitLabel left='Address:' type='link' right={delivery.geoAddress} href={h} />
                        <SplitLabel left='Product:' right="" />
                        {hardwood}
                        {cedar}
                        {black}
                        {red}
                        <SplitLabel left='Notes:' right={delivery.notes} />
                        <SplitLabel3 left='Scout:' middle={delivery.scout} right={delivery.scoutPhone} rightType='phone' />
                        <DeliveryOrderButtons delivered={this.props.delivered} remove={this.props.remove} />
                    </div>
                )
            }
            else {
                return (
                    <div />
                )
            }
        }
        else {
            return (
                <div />
            )
        }
    }
}

const mapStateToProps = state => {
    console.log(state);
    return {
        delivery: state.orders.delivery
    };
}

export default connect(mapStateToProps) (DeliveryOrder);