import React, { Component } from 'react';
import classes from './SplitLabel3.module.css';
import { FaPhone } from 'react-icons/fa';
import { FaCheckCircle } from 'react-icons/fa';
import { FaPlusCircle } from 'react-icons/fa';
import Modal from '../UI/Modal/Modal';
import ShowPhoneModal from "../ShowPhoneModal/ShowPhoneModal";

class SplitLabel3 extends Component {
    state = {
        phoneNumber: '',
        showPhoneModal: false
    }


    showPhone = (phoneNumber) => {
        this.setState({phoneNumber:phoneNumber,showPhoneModal:true});
    }

    cancelShowPhone = () => {
        this.setState({phoneNumber:'',showPhoneModal:false});
    }

    render () {
        if (this.props.rightType == 'phone') {
            let modal = '';

            if (this.state.showPhoneModal) {
                modal = <ShowPhoneModal phoneNumber={this.props.right} cancelShowPhone={this.cancelShowPhone} />;
            }
            
            return (
                <div>
                    <Modal show={this.state.showPhoneModal} modalClosed={this.cancelShowPhone}>
                        {modal}
                    </Modal>
                    <span className={classes.LeftSide}>
                        <strong>{this.props.left}</strong>
                    </span>                   
                    <span className={classes.Middle}>
                        &nbsp;{this.props.middle} <FaPhone color="black" onClick={(event) => this.showPhone(this.props.right)}/>
                    </span>
                    <span className={classes.RightSide}>
                      
                    </span>
                </div>
                
                )
        }
        else if (this.props.type && this.props.type == 'link') {
            return (
                <div>
                    <span className={classes.LeftSide}>
                        <strong>{this.props.left}</strong>
                    </span>
                    <span className={classes.Middle}>
                        <a target='blank' href={this.props.href}>{this.props.middle}</a>
                    </span>
                    <span className={classes.RightSide}>
                        {this.props.right}
                    </span>
                </div>
                )
        }
        else {

    return (
    <div className={classes.SplitLabel}>
        <div className={classes.LeftSide}>
            <strong>{this.props.left}</strong>
        </div>
        <div className={classes.Middile}>
            {this.props.middle}
        </div>
        <div className={classes.RightSide}>
            {this.props.right}
        </div>
    </div>
    )
        }
    }
}

export default SplitLabel3