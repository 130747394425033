import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  withRouter, Redirect } from 'react-router-dom';
import Header from './components/Header/Header';
import Auth from './components/Security/Auth/Auth';
import * as actions from './store/actions/index';
import classes from './App.css';
import Delivery from './components/Delivery/Delivery';
import Toolbar from './components/Navigation/Toolbar/Toolbar';
import SideDrawer from './components/Navigation/SideDrawer/SideDrawer';

 
class App extends Component {
  state = {
    page: null,
    signingUp: false,
    deliveryId: 0,
    showSideDrawer: false
  }
  componentDidMount() {
    this.props.getMulchYear();
    this.props.onTryAutoSignup();
    console.log("In APP DID MOUNT");
    
  }

  componentDidUpdate() {
    if (this.props.location.pathname == '/logout') {
      this.props.onLogout();
    }
  }

  sideDrawerClosedHandler = () => {
    this.setState( { showSideDrawer: false } );
}

sideDrawerToggleHandler = () => {
    this.setState( ( prevState ) => {
        return { showSideDrawer: !prevState.showSideDrawer };
    } );
}


  setPage = (page) => {
    console.log(page);
  }

  setLoadDelivery = (deliveryId) => {
    this.setState({deliveryId:deliveryId,page:'loaddelivery'});

  }

  deliveryLoaded = () => {
    this.props.onSendDeliveryOut(this.state.deliveryId);
    this.setState({deliveryId:0,page:'loads'});
  }

  loadDeliveryCancelled = () => {
    this.setState({deliveryId:0,page:'loads'});
  }

  render() {
    if (this.props.location.pathname == '/logout') {
      return (
<Redirect to="/" />
      );
    }
    let pageToShow = null;
    if (!this.props.isAuthenticated )
    {
      pageToShow = <Auth setPage={this.setPage} />
    }
    else {
      pageToShow = <Delivery deliveryId={this.state.deliveryId}/>
    }

        return (
          <div className={classes.App} id='app' style={{ "height": "100%" }}>
            <Toolbar isAuth={this.props.isAuthenticated}
                     drawerToggleClicked={this.sideDrawerToggleHandler} />
                <SideDrawer
                    isAuth={this.props.isAuthenticated}
                    open={this.state.showSideDrawer}
                    closed={this.sideDrawerClosedHandler} />
                    <div style={{"height":"100%"}}>
            {pageToShow}
            </div>
          </div>  
        );
    }
}

const mapStateToProps = state => {
  console.log(state);
  return {
    isAuthenticated: state.orders.token !== null,
    delivery: state.orders.delivery
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onGetDelivery: () => dispatch(actions.getDelivery()),
    getMulchYear: () => dispatch(actions.getMulchYear()),
    onLogout: () => dispatch(actions.logout())
  };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( App ) );
