import React, { Component } from 'react';
import Button from '../UI/Button/Button';

class RemoveModal extends Component {
    state = {
        message: ''
    }

    removeOrder = () => {
        this.props.removeConfirmed(this.state.message);
    } 

    updateInputValue(evt) {
        this.setState({
          message: evt.target.value
        });
      }


    render () {
        let disabled = true;
        if (this.state.message.length > 5) {
            disabled = false;
        }
            return (
                <div>
                    <h3>Confirm Removal</h3>
                <p>Please enter a reason.
                </p>        
                <input value={this.state.message} placeholder='Reason' onChange={evt => this.updateInputValue(evt)}/>
                <br />    
                <Button disabled={disabled} btnType="Success" clicked={this.removeOrder}>Remove</Button>
                <Button btnType="Danger" clicked={this.props.removeModalClosed}>CANCEL</Button>
                    </div>
            )        

        
    }
}


export default RemoveModal;