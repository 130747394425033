import React, { Component } from 'react';
import { connect } from 'react-redux';
import classes from './DeliveryOrderButtons.module.css';
import Button from '../../components/UI/Button/Button';


class DeliveryOrderButtons extends Component {
    render () {   
        if (this.props.delivery && this.props.delivery.status == 'delivering') {     
        return (
            <div className={classes.SignUpButtons}>
                
                <Button 
                    btnType="Success"
                    clicked={this.props.delivered}>Delivered</Button>

                <Button 
                    btnType="Danger"
                    clicked={this.props.remove}>Remove</Button>
            </div>
        );
        }
        else {
            return (
<div className={classes.SignUpButtons}>Click Loaded to start delivering</div>
            );
        }
    }
};

const mapStateToProps = state => {
    console.log(state);
    return {
        delivery: state.orders.delivery
    };
}

export default connect(mapStateToProps) (DeliveryOrderButtons);