export const LOAD_START = 'LOAD_START';
export const LOAD_RESULT = 'LOAD_RESULT';
export const MESSAGE_FAIL = 'MESSAGE_FAIL';

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const INIT_ORDER = 'INIT_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_GEOCODE = 'UPDATE_GEOCODE';
export const SUBMIT_EDIT_ORDER = 'SUBMIT_EDIT_ORDER';
export const SUBMIT_EDIT_ORDER_SUCCESS = 'SUBMIT_EDIT_ORDER_SUCCESS';
export const SUBMIT_ORDER = 'SUBMIT_ORDER';
export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS';
export const SET_EDIT_ORDER = 'SET_EDIT_ORDER';
export const SET_ADD_CALLLIST_ORDER = 'SET_ADD_CALLLIST_ORDER';

export const FETCH_DOCUMENTS_START = 'FETCH_DOCUMENTS_START';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAIL = 'FETCH_DOCUMENTS_FAIL';
export const ADD_ORDER_TO_ORDERS = 'ADD_ORDER_TO_ORDERS';
export const UPDATE_EXISTING_ORDER = 'UPDATE_EXISTING_ORDER';

export const UPDATE_SEARCH_CRITERIA = 'UPDATE_SEARCH_CRITERIA';

export const SEARCH_ADMIN_CALLLIST_START = 'SEARCH_ADMIN_CALLLIST_START';
export const SEARCH_ADMIN_CALLLIST_SUCCESS = 'SEARCH_ADMIN_CALLLIST_SUCCESS';
export const SEARCH_ADMIN_CALLLIST_FAIL = 'SEARCH_ADMIN_CALLLIST_FAIL';

export const FETCH_ORDER_START = 'FETCH_ORDER_START';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';

export const INIT_SEARCH_RESULTS = 'INIT_SEARCH_RESULTS';


export const FETCH_SCOUTORDERS_SUCCESS = 'FETCH_SCOUTORDERS_SUCCESS';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';

export const FETCH_CALLLIST_SUCCESS = 'FETCH_CALLLIST_SUCCESS';

export const TICK_ORDERSSUMMARY = 'TICK_ORDERSUMMARY'
export const FETCH_ORDERSSUMMARY_SUCCESS = 'FETCH_ORDERSSUMMARY_SUCCESS'

export const FETCH_MAPORDERS_START = 'FETCH_MAPORDERS_START';
export const FETCH_MAPORDERS_SUCCESS = 'FETCH_MAPORDERS_SUCCESS';
export const FETCH_MAPORDERS_FAIL = 'FETCH_MAPORDERS_FAIL';

export const ADD_MAP_ORDER_TO_DELIVERY = 'ADD_ORDER_TO_DELIVERY';
export const REMOVE_MAP_ORDER_FROM_DELIVERY = 'REMOVE_ORDER_FROM_DELIVERY';

export const INIT_DELIVERY_MULCH_TYPES = 'INIT_DELIVERY_MULCH_TYPES';
export const UPDATE_DELIVERY_MULCH_TYPE = 'UPDATE_DELIVERY_MULCH_TYPE';

export const FETCH_MAPCITIES_START = 'FETCH_MAPCITIES_START';
export const FETCH_MAPCITIES_SUCCESS = 'FETCH_MAPCITIES_SUCCESS';
export const FETCH_MAPCITIES_FAIL = 'FETCH_MAPCITIES_FAIL';
export const UPDATE_MAPCITY = 'UPDATE_MAPCITY';

export const FETCH_DELIVERY_DRIVERS_START = 'FETCH_DELIVERY_DRIVERS_START';
export const FETCH_DELIVERY_DRIVERS_SUCCESS = 'FETCH_DELIVERY_DRIVERS_SUCCESS';
export const FETCH_DELIVERY_DRIVERS_FAIL = 'FETCH_DELIVERY_DRIVERS_FAIL';
export const UPDATE_SELECTED_DELIVERY_DRIVER = 'UPDATE_SELECTED_DELIVERY_DRIVER';

export const INIT_DELIVERY = 'INIT_DELIVERY';
export const UPDATE_DELIVERY = 'UPDATE_DELIVERY';
export const ADD_ORDER_TO_DELIVERY = 'ADD_ORDER_TO_DELIVERY';
export const REMOVE_ORDER_FROM_DELIVERY = 'REMOVE_ORDER_FROM_DELIVERY';

export const FETCH_DELIVERIES_START = 'FETCH_DELIVERIES_START';
export const FETCH_DELIVERIES_SUCCESS = 'FETCH_DELIVERIES_SUCCESS';
export const FETCH_DELIVERIES_FAIL = 'FETCH_DELIVERIES_FAIL';

export const GET_SCOUTS_START = 'GET_SCOUTS_START';
export const GET_SCOUTS_SUCCESS = 'GET_SCOUTS_SUCCESS';
export const GET_SCOUTS_FAIL = 'GET_SCOUTS_FAIL';

export const ADD_DELIVERY_TO_DELIVERIES = 'ADD_DELIVERY_TO_DELIVERIES';