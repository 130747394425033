import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '../../components/UI/Table/Table';
import classes from './DeliveryOrders.module.css';

class DeliveryOrders extends Component {
    componentDidMount() {
    }

    orderClicked = (e) => {
        let oid = e.currentTarget.getAttribute('oid')
        
        this.props.onClick(oid);
    }


    actionClicked = (e,oid,action) => {
        console.log(e.currentTarget.getAttribute('oid'));
console.log(oid + ' clicked for action ' + action);
    }

    render() {
        console.log(this.props);
        if (this.props.delivery.orders && this.props.delivery.orders.length > 0) {
        let lets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let letter = 0;
        var data = [];
        var delivered = [];
        var header = [];
        var delheader = [];
        let pl = "30px";
        if (window.innerWidth < 500) {
            pl = "5px";
        }

        header.push({
            name: "",
            style: { paddingLeft: pl }
        },
            {
                name: "Address",
                style: { paddingLeft: pl }
            },
            {
                name: "Product",
                style: { paddingLeft: pl }
            },
            {
                name: "Count",
                style: { paddingLeft: pl }
            }
        );

        delheader.push({
            name: "",
            style: { paddingLeft: pl }
        },
            {
                name: "Address",
                style: { paddingLeft: pl }
            },
            {
                name: "Product",
                style: { paddingLeft: pl }
            },
            {
                name: "Count",
                style: { paddingLeft: pl }
            }
        );

        for (let l in this.props.delivery.orders) {
            letter = letter + 1;
            
                let product = '';
                let totalBags = 0;
                let s = '';
                if (this.props.delivery.orders[l].hardwoodBags > 0) {
                    totalBags = totalBags + parseInt(this.props.delivery.orders[l].hardwoodBags);
                    product = 'Hardwood';
                    s = ', '
                }
                if (this.props.delivery.orders[l].cedarBags > 0) {
                    totalBags = totalBags + parseInt(this.props.delivery.orders[l].cedarBags);
                    product = product + s + 'Cedar';
                    s = ', '
                }
                if (this.props.delivery.orders[l].blackBags > 0) {
                    totalBags = totalBags + parseInt(this.props.delivery.orders[l].blackBags);
                    product = product + s + 'Black';
                    s = ', '
                }
                if (this.props.delivery.orders[l].redBags > 0) {
                    totalBags = totalBags + parseInt(this.props.delivery.orders[l].redBags);
                    product = product + s + 'Red';
                    s = ', '
                }
                if (this.props.delivery.orders[l].status != 'delivered') {
                data.push(
                    {
                        oid: this.props.delivery.orders[l].orderId,
                        data: [
                            {
                                value: letter,
                                style: { paddingLeft: pl }
                            },
                            {
                                value: this.props.delivery.orders[l].geoAddress,
                                style: { paddingLeft: pl }
                            },
                            {
                                value: product,
                                style: { paddingLeft: pl }
                            },
                            {
                                value: totalBags,
                                style: { paddingLeft: pl }
                            }
                        ]
                    }
                )
                }
                else {
                    delivered.push(
                        {
                            oid: this.props.delivery.orders[l].orderId,
                            data: [
                                {
                                    value: letter,
                                    style: { paddingLeft: pl }
                                },
                                {
                                    value: this.props.delivery.orders[l].geoAddress,
                                    style: { paddingLeft: pl }
                                },
                                {
                                    value: product,
                                    style: { paddingLeft: pl }
                                },
                                {
                                    value: totalBags,
                                    style: { paddingLeft: pl }
                                }
                            ]
                        }
                    )
                }
        }


        return (
            <div>
            <Table header={header} data={data} onClick={this.orderClicked}></Table>
            <br />
            <label>Delivered</label>
            <Table header={delheader} data={delivered}></Table>
            </div>
        );
        }
        else {
            return (
                <div />
            );
        }
    }
}


const mapStateToProps = state => {

    return {
        delivery: state.orders.delivery,
        loading: state.orders.loading
    };
};

export default connect(mapStateToProps)(DeliveryOrders);