import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import classes from './DeliveryMap.module.css';
import * as actions from '../../store/actions/index';

var map = null;
var markers = [];

var green = "green";
var red = "default";
var hardwood = "orange";
var black = "gray";
var cedar = 'tan'
var multiple = "violet";

class DeliveryMap extends React.Component {
state = {
    markersSet: false
}
    componentDidMount() {
        console.log('display the map');
        console.log(document.getElementById('map'));
        map = new window.google.maps.Map(document.getElementById('map'), {
            center: { lat: 33.19501, lng: -96.67336 },
            zoom: 13,
            mapTypeId: 'roadmap',
        });

    }

    componentDidUpdate() {
        console.log("In Did Update");
        let lets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (var i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
        }
        markers = [];
        let bounds = new window.google.maps.LatLngBounds();
        let letter = 0;
        //if (markers.length == 0 || !this.state.markersSet) {
        if (this.props.delivery && this.props.delivery.orders &&this.props.delivery.orders.length > 0) {
            for (let l in this.props.delivery.orders) {
                letter = letter + 1;
                this.addMarker(map, this.props.delivery.orders[l], letter)
                bounds.extend({ lat: Number(this.props.delivery.orders[l].geoLat), lng: Number(this.props.delivery.orders[l].geoLon) })
            }
            if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
                var extendPoint1 = new window.google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
                var extendPoint2 = new window.google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
                bounds.extend(extendPoint1);
                bounds.extend(extendPoint2);
             }
            //this.setState({markersSet:true});
            map.fitBounds(bounds);
        }
    }


    checkMarker(map, order) {
        console.log(order);
        let color = "";
        let types = 0;
        let lets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let bags = order.hardwoodBags + order.cedarBags + order.blackBags + order.redBags;
        for (let k in markers) {
            let letter = k + 1;
            let orderId = markers[k].title.substring(0, markers[k].title.indexOf(":"));
            if (orderId == order.orderId) {
   
                if (order.status == 'delivered') {
                    color = green;
                    console.log("Order is green");
                }
                else {                    
                    if (order.hardwoodBags > 0) {
                        types = types + 1;
                        color = hardwood;
                    }
                    if (order.cedarBags > 0) {
                        types = types + 1;
                        color = cedar;
                    }
                    if (order.blackBags > 0) {
                        types = types + 1;
                        color = black;
                    }
                    if (order.redBags > 0) {
                        types = types + 1;
                        color = red;
                    }

                    if (types > 1) {
                        color = multiple;
                    }
                }
                console.log(color);
                markers[k].icon.url = 'https://www.mapcustomizer.com/markers/51120e9b/png/' + color + '/' + letter;
               
                console.log(markers[k].icon.url);
            }
        }
    }

    toggleOrder(title) {
        let orderId = title.title.substring(0, title.title.indexOf(":"));
        this.props.orderClicked(orderId);
    }

    addMarker(map, order,letter) {
        let title = order.orderId + ":";
        let color = "";

        let types = 0;
        let bags = order.hardwoodBags + order.cedarBags + order.blackBags + order.redBags;
        if (order.status == 'delivered') {
            color = green;
        }
        else {
        if (order.hardwoodBags > 0) {
            title = title + ' Hardwood ' + order.hardwoodBags;
            types = types + 1;
            color = hardwood;
        }
        if (order.cedarBags > 0) {
            title = title + ' Cedar ' + order.cedarBags;
            types = types + 1;
            color = cedar;
        }
        if (order.blackBags > 0) {
            title = title + ' Black ' + order.blackBags;
            types = types + 1;
            color = black;
        }
        if (order.redBags > 0) {
            title = title + ' Red ' + order.redBags;
            types = types + 1;
            color = red;
        }

        if (types > 1) {
            color = multiple;
        }
        
        title = title + ' bags';
    }
        let marker = new window.google.maps.Marker({
            map: map,
            title: title,
            icon: { url: 'https://www.mapcustomizer.com/markers/51120e9b/png/' + color + '/' + letter },
            position: { lat: Number(order.geoLat), lng: Number(order.geoLon) },
        });
        if (order.status != 'delivered') {
        marker.addListener('click', () => {
            this.toggleOrder({ title });
            //markers[0].setIcon({url: 'https://chart.googleapis.com/chart?chst=d_map_spin&chld=0.75|0|aa0000|10|_|100'});
        })
        }

        markers.push(marker);

    }


    render() {
        return (
            <div style={{ "height": "100%" }}>
                <div id='map' style={{ "height": "100%", marginTop:56 }} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        delivery: state.orders.delivery,
        isAuthenticated: state.orders.token !== null
    };
}


export default connect(mapStateToProps)(DeliveryMap);