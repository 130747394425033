import React from 'react';
import classes from './Table.module.css';
import Button from '../../../components/UI/Button/Button';
import { FaPhone } from 'react-icons/fa';
import { FaCheckCircle } from 'react-icons/fa';
import { FaPlusCircle } from 'react-icons/fa';

const table = ( props ) => {
    let header = "";
    let rows = "";
    let i = 0;
    header  = props.header.map( 
        
        function(h) {
            i = i + 1;       
            let c = classes.cell;
            
            if (h.full)
            {
                c= [classes.cell, classes.full].join(' ');
            }
            if (h.sort && h.sort !== '') {
                return <div onClick={(event) => props.onSort(h.sort)} key={i} style={h.style}  className={c}>{h.name}</div>
            }
            else {
                return <div key={i} style={h.style}  className={c}>{h.name}</div>
            }
        }
    );

    
    rows = props.data.map(
        function(r) {
            let i = 0;
            var rw = r.data.map(
                function(d) {
                    let c = classes.cell;
                    i = i + 1;
                    let key = 'h' + i;
                    
                    if (d.full)
                    {
                        c= [classes.cell, classes.full].join(' ');
                    
                    }
                    if (d.type == 'button') {
                        return  <div key={key} className={c} style={d.style}> <Button 
                        btnType="Success"
                        clicked={(event) => props.onClick(event,d.oid)}>{d.label}</Button>
                        </div>
                    }
                    else if (d.type == 'imagebutton') {
                        let img = <FaPlusCircle color="green" onClick={(event) => props.onActionClick(event,r.oid,d.action)} />
                        if (d.icon == "cancel") {
                            img = <FaPlusCircle color="green" onClick={(event) => props.onActionClick(event,r.oid,d.action)} />
                        }
                        else if (d.icon == "phone") {
                            img = <FaPhone color="black" onClick={(event) => props.onActionClick(event,d.oid,d.action)} />
                        }
                        return  <div key={key} className={c} style={d.style}> <Button>
                            {img}</Button>
                        </div>
                    }
                    else if (d.link && d.link !== "") {
                        return  <div key={key} className={c} style={d.style}><a href={d.link} target={d.target}>{d.value}</a></div>
                    }
                    
                    else {
                        return  <div key={key} className={c} style={d.style}>{d.value}</div>
                    }
            });
            if (props.onClick) {
                return <div key={r.oid} oid={r.oid} className={classes.row} onClick={(event) => props.onClick(event,r.oid)}>{rw}</div>
            }
            else {
                return <div key={r.oid} oid={r.oid} className={classes.row}>{rw}</div>
            }
        });
    return (
        <div className={classes.maxarea}>
            <div className={classes.fulltable}>
                <div className={classes.wraptable}>
                    <div className={classes.table}>
                        <div className={classes.row  + ' ' + classes.header}>
                            {header}
                        </div>
                        {rows}
                    </div>
                </div>
            </div>
        </div> 
    );

};

export default table;