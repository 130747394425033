import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Blank from '../../../hoc/Blank/Blank';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import Spinner from '../../UI/Spinner/Spinner';
import classes from './Auth.module.css';
import * as actions from '../../../store/actions/index';
import { updateObject, checkValidity } from '../../../shared/utility';


class Auth extends Component {
    state = {
        controls: {
            phoneNumber: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Phone Number'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            }
        },
        isSignup: true,
        showModal: false
    }

    componentDidMount () {
    }

    componentWillUpdate () {
        if (this.props.isAuthenticated) {
            this.props.setPage('loads');
        }
    }

    inputChangedHandler = ( event, controlName ) => {
        const updatedControls = updateObject( this.state.controls, {
            [controlName]: updateObject( this.state.controls[controlName], {
                value: event.target.value,
                valid: checkValidity( event.target.value, this.state.controls[controlName].validation ),
                touched: true
            } )
        } );
        this.setState( { controls: updatedControls } );
    }

    submitHandler = ( event ) => {
        event.preventDefault();
        this.props.onAuth( this.state.controls.phoneNumber.value, this.state.isSignup );
    }

   


    render () {
        const formElementsArray = [];
        for ( let key in this.state.controls ) {
            formElementsArray.push( {
                id: key,
                config: this.state.controls[key]
            } );
        }

        let form = formElementsArray.map( formElement => (
            <Input
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={( event ) => this.inputChangedHandler( event, formElement.id )} />
        ) );

        if ( this.props.loading ) {
            form = <Spinner />
        }

        let errorMessage = null;

        if ( this.props.error ) {
            errorMessage = (
                <p>{this.props.error.message}</p>
            );
        }

        return (
            <Blank>
            <div className={classes.Auth}>
                {errorMessage}
                <form>
                    {form}
                    <Button btnType="Success" clicked={this.submitHandler}>SUBMIT</Button>
                    
                </form>
               
            </div>
            </Blank>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.orders.loading,
        error: state.orders.errorMessage,
        isAuthenticated: state.orders.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: ( email,  isSignup ) => dispatch( actions.auth( email, isSignup ) )
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( Auth );