import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeliveryMapOptions from '../DeliveryMapOptions/DeliveryMapOptions';
import * as actions from '../../store/actions/index';
import DeliveryMap from '../DeliveryMap/DeliveryMap';
import DeliveryOrders from '../DeliveryOrders/DeliveryOrders';
import DeliveryOrder from '../DeliveryOrder/DeliveryOrder';
import DeliveredModal from '../Modals/DeliveredModal';
import RemoveModal from '../Modals/RemoveModal';
import Modal from '../UI/Modal/Modal';

class Delivery extends Component {
    state = {
        deliveryId: 0,
        state: 'overview',
        delivered:false,
        remove: false
    }


    componentDidMount () {
        this.props.getDelivery();  
             console.log(React.version);
    }

    componentDidUpdate() {
        //if (this.props.delivery && this.props.delivery.deliveryId !== this.state.deliveryId) {
        //    this.setState({deliveryId: this.props.delivery.deliveryId})
        //    if (this.props.delivery.currentOrder == 0) {
        //        if (this.props.delivery.orders && this.props.delivery.orders.count > 0) {
        //            let found = false;
        //            for (let l in this.props.delivery.orders) {
        //                if (!found && this.props.delivery.orders[l].orderId == this.props.delivery.currentOrder && this.props.delivery.orders[l].status !== 'delivered') {
        //                    this.props.onChangeCurrentDeliveryOrder(this.props.delivery.orders[l].orderId);
        //                    found = true
        //                }
        //            }
        //            
        //        }
        //    }
        //  } 
          
    }

    delivered = () => {
        this.setState({delivered:true});
    }

    delveryConfirmed = () => {
        this.props.onDeliverOrder();
        this.setState({delivered:false});
    }

    remove = () => {
        console.log("REMOVE");
        this.setState({remove:true});
    }

    removeConfirmed = (message) => {
        this.props.onRemoveOrder(message);
        this.setState({remove:false});
    }

    deliveryModalClosed = () => {
        this.setState({delivered:false});
    }

    removeModalClosed = () => {
        this.setState({remove:false});
    }
    
    available = () => {
        this.props.onUpdateDriverStatus('ready');
    }

    unavailable = () => {
        this.props.onUpdateDriverStatus('unavailable');
    }

    orderClicked = (oid) => {
        console.log(oid);
        this.props.onChangeCurrentDeliveryOrder(oid);   
    }

    loaded = () => {
        this.props.onUpdateDeliveryStatus(this.props.delivery.deliveryId,'delivering');
    }
    render () {
        let showSetAvailability = true;
        if (this.props.delivery && this.props.delivery.orders) {
        for (let k in this.props.delivery.orders) {
            if ( this.props.delivery.orders[k].status != 'delivered') {
                showSetAvailability = false;
            }
        }
    }
    else {
        showSetAvailability = true;
    }
  
    let deliveryOptions = '';
    
    if (this.props.delivery && this.props.delivery.orders) {
        return (
            <div style={{"height":"100%"}}>
                <div style={{height:10}}  />
                <div style={{"height":"50%",marginTop:20}}>
                    <DeliveryMap id="deliveryMap"
                        deliveryId={this.state.delivseryId}  
                        key='map' 
                        
                        orderClicked={this.orderClicked}
                    />
                    <div>
                        <DeliveryMapOptions loaded={this.loaded} available={this.available} unavailable={this.unavailable}
                        state={this.state} overview={this.showOverview} directions={this.showDirections}/>
                    </div>
                    <DeliveryOrder delivered={this.delivered} remove={this.remove}/>
                    <DeliveryOrders onClick={this.orderClicked} />
                </div>
                <Modal show={this.state.delivered} modalClosed={this.deliveryModalClosed}>
                    <DeliveredModal delveryConfirmed={this.delveryConfirmed} deliverymodalClosed={this.deliveryModalClosed} />
                </Modal>
                <Modal show={this.state.remove} modalClosed={this.removeModalClosed}>
                    <RemoveModal removeConfirmed={this.removeConfirmed} removeModalClosed={this.removeModalClosed} />
                </Modal>
            </div>
        )
    }
    else {
        return (            
            <div style={{"height":"100%"}}>
                <div style={{height:10}}  />
                <div style={{"height":"70%",marginTop:20}}>
                <DeliveryMap id="deliveryMap"
            deliveryId={this.state.deliveryId}  
            key='map' 
            style={{"height":"200"}}
            orderClicked={this.orderClicked}
            />

                </div>
                <div>
                        <DeliveryMapOptions loaded={this.loaded} available={this.available} unavailable={this.unavailable} state={this.state} overview={this.showOverview} directions={this.showDirections}/>
                    </div>                
            </div>
        )
    }
    }
}

const mapStateToProps = state => {
    console.log(state);
    return {
        delivery: state.orders.delivery,
        isAuthenticated: state.orders.token !== null,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getDelivery: () => dispatch(actions.getDelivery()),
        onChangeCurrentDeliveryOrder: (oid) => dispatch(actions.changeCurrentDeliveryOrder(oid)),
        onUpdateDeliveryStatus : (deliveryId,status) => dispatch(actions.updateDeliveryStatus(deliveryId,status)),
        onDeliverOrder: () => dispatch(actions.deliverOrder()),
        onRemoveOrder: (message) => dispatch(actions.removeOrder(message)),
        onUpdateDriverStatus : (status) => dispatch(actions.updateDriverStatus(status))
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(Delivery);