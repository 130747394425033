import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Button from '../UI/Button/Button';

class DeliveredModal extends Component {
    render () {
        return (
            <div>
                <h3>Confirm Delivery</h3>
                <p>Please confirm that you have 
                <br />delivered the mulch per
                <br />customers instructions.
                </p>                
                <Button btnType="Success" clicked={this.props.delveryConfirmed}>DELIVERED</Button>
                <Button btnType="Danger" clicked={this.props.deliverymodalClosed}>CANCEL</Button>
            </div>
        );
    }
}

export default DeliveredModal;