import React, { Component } from 'react';
import Button from '../UI/Button/Button';
class ShowPhoneModal extends Component {
    render () {
        let phone = this.props.phoneNumber;
        phone = phone.replace(' ','');
        phone = phone.replace('-','');
        phone = phone.replace('(','');
        phone = phone.replace(')','');
        phone = phone.replace('+','');
        if (this.props.phoneNumber != '') {
            return (
                <div> 
                    Phone: <a href='tel:{phone}'>{phone}</a>
                    <br />
                    <Button btnType="Success"
                    clicked={this.props.cancelShowPhone}>CANCEL</Button>
                </div>
            )        
        }
        else {
            return (
                <div />
            )
        }
    }
}

export default (ShowPhoneModal)